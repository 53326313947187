@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa');
@import url('https://fonts.googleapis.com/css2?family=Tektur');
@import url('https://fonts.googleapis.com/css2?family=Silkscreen');
@import url('https://fonts.googleapis.com/css2?family=Mynerve');
@import url('https://fonts.googleapis.com/css2?family=Rampart+One');
.App {
  background-color: #1b1a18;
  height:100vh;
  width: 100vw;
}
.Menyu{
  background-color: transparent;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1a18;
overflow: hidden;
}
.bigTitle{
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  color: #1b1a18;
  letter-spacing: 0.2em;
  text-align: left;
  padding:10px;
  transition: border 0.6s ease-in-out;
  border-radius: 20px;
  
}
.nfg{
  font-size: 75px;
  font-family: 'Creepster';
  align-self: center;
  color:#452c43;
  padding-inline: 100px;
}
.nfg:hover {
  border-left: 10px solid #452c43;
  border-right: 10px solid #452c43;
}

.mainHeader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.cell {
  flex: 1 1 20%;
  color: #fff;
  font-size: 30px;
  box-sizing: border-box;
  padding: 20px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
}
.menuContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 800px;
  margin: 0 auto;
}
.AboutUs:hover {
  border-left: 10px solid #FFA07A;
  border-right: 10px solid #FFA07A;
}
.Music:hover {
  border-left: 10px solid #98FB98;
  border-right: 10px solid #98FB98;
}
.Shows:hover {
  border-left: 10px solid #87CEFA;
  border-right: 10px solid #87CEFA;
}
.Contact:hover {
  border-left: 10px solid #dc7082;
  border-right: 10px solid #dc7082;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.canvasContainer{
  background-image: url("./assets/delancey.jpeg");
  background-size: auto; /* Ensures the background covers the entire container */
  background-repeat: no-repeat; /* Prevents background from repeating */
  background-position: center; /* Centers the background */
  height: 100vh;
  width: 100vw;
  z-index: -1;
  opacity: 0;
  transition: opacity 2s ease; 
  overflow:hidden;
  overflow-x:hidden;

}
.MyCanvas{
  height: 100vh; /* Ensure the canvas fills the entire container */

  z-index: -1;
}

.footer{
  font-family: 'Montserrat', sans-serif;
  color: rgb(238, 234, 227);
  border-color:#fff;
  border-radius: 10px;
  border-width: 1px;
  position: absolute; /* Position the footer absolutely */
  bottom: 0; /* Align the footer to the bottom */
  width: 100vw; /* Ensure the footer spans the entire width */
  padding: 10px; /* Add padding to the footer */
  background-color: rgba(21, 0, 0, 0); /* Optional: Add a semi-transparent background */
  text-align: center; /* Center-align the text within the footer */
  font-size: medium;
}
.modal {
  left: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 70%;
  height: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b0236 #7bdaef;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* Add transition for left property with a duration */
  transition: left 0.5s ease-in-out;
}

.modal.open {
  /* Set the left property to 0 when modal is open */
  left: 30%;
}

.modal.open.mobile{
  left: 0%;
  width: 100%;
}
.modal-header{
  color: #87CEFA;
  font-family: 'Creepster';
  font-size: 6vh;
  opacity:0;
  margin-right:20px;
  transition:all 1s ease-in-out;
}
.modal-header.loaded {
  opacity: 1;
  margin-right:20px;

}
.modal-description{
  color:#87CEFA;
  font-family:'Comfortaa';
  font-size: 3vh;
}
.exit-button{
  background-color: transparent;
  border: transparent;
  float: left;
  margin-left: -5px;
}
.show-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px; /* Add padding as needed */
}
.show-item {
  width: 400px;
   margin-inline :auto;
   margin-block: 10px;
  /* Additional styling for each show item */
}
.prev-show-item {
  flex: 1 0 25%; /* Adjust the width of each item based on your layout requirements */
  max-width: 300px; /* Set maximum width for each item */
  margin: 10px; /* Add margin between items */
  /* Add any additional styles for the items as needed */
}
.description-box{
  background-color: #000000;
  text-align: left;
}
.img-description{
  color:#aedaf6;
  font-family:'Montserrat';
  font-size: 18px;
  padding: 10px;
  margin: 10px;
}
.music-page-container{
  text-align: center;
  height: 100vh;
  overflow-y: scroll;

}
.music-page-container::-webkit-scrollbar {
  width: 10px;
}

.music-page-container::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.music-page-container::-webkit-scrollbar-track {
  background-color: #603270;
}
.shows-page-container{
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #2b0236 #7bdaef;
}
.about-us-container {
  text-align: center;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;

}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px; /* Adjust the gap between items as needed */
  position:relative;
  transition:all 1s ease-in-out;
}

.grid-item {
  border: 2px solid #7cb7f2; /* Add border or other styling as needed */
  border-radius: 20px;
  color:rgb(184, 213, 255);
  padding: 20px;
  margin: 10px;
  opacity: 0;
  text-align: center;
  height:250px;
  transition:all 1s ease-in-out;
}
.grid-item.loaded {
  opacity: 1;
  margin-right:20px;

}
.grid-desc{
  background: rgba(255, 255, 255, 0.832);
  font-family: 'Comfortaa';
  padding:30px;
  font-weight: 500;

}

.floating-description {
  width: 80%; /* Adjust as needed */
  margin: 10px auto; /* Add margin for spacing */
  padding: 10px; /* Add padding for readability */
  text-align: center; /* Center-align text */
  transition: all 0.5s ease; /* Add transition for opacity */
}
.say-june{
  font-family: "Tektur";
  font-size: 30px;
}
.bradley-cd{
  font-family: "Silkscreen";
  font-size: 40px;
}
.dave-langston{
  font-family: "Mynerve";
  font-size: 30px;
}
.kaylee{
  font-family: "Rampart One";
  font-size: 40px;
  letter-spacing: 4px;
}